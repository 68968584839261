<template>
    <div>
        <div
            ref="affiliateReport"
            class="mainContainer"
            style="position: relative"
        >
            <div class="reportContainer">
                <div style="margin-top: 20px">
                    <v-row style="margin-left: 0">
                        <v-col cols="4" class="py-0">
                            <div style="width: 80%;">
                                <p style="text-align: center; font-weight: bold; margin: 0;">Fecha</p>
                                <p style="text-align: center; margin: 0;" class="borderReport">{{ affiliate.affiliationDate }}</p>
                            </div>
                            <div style="width: 80%;">
                                <p style="text-align: center; font-weight: bold; margin: 0;">Oficina</p>
                                <p style="text-align: center; margin: 0;" class="borderReport">{{ city.name }}</p>
                            </div>
                        </v-col>
                        <v-col cols="4" class="py-0">
                            <h2 style="text-align: center;">HOJA DE VIDA</h2>
                            <h3 style="text-align: center;">PROCESO AFILIACIONES</h3>
                        </v-col>
                        <v-col cols="4" class="py-0">
                            <div class="QRContainerAfil">
                                <img v-if="logo" ref="reportImg" :src="logo" alt="" style="height: 100px; width: auto; margin-right: 12px; max-width: 300px;">
                                <div v-else class="QRContainerAf">
                                    <canvas ref="canvasAf"></canvas>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </div>

                <div style="padding: 0; margin: 0; margin-top: 6px">
                    <v-row style="padding: 0; margin: 0;">
                        <v-col cols="7" style="margin: 0; padding-top: 0; padding-bottom: 0;">
                            <p style="text-align: center; font-weight: bold; margin: 0; margin-bottom: 2px;">Nombres y apellidos del afiliado</p>
                            <p style="text-align: center; margin: 0;" class="borderReport">{{ affiliate.name }}</p>
                        </v-col>
                        <v-col cols="3" style="margin: 0; padding-top: 0; padding-bottom: 0;">
                            <p style="text-align: center; font-weight: bold; margin: 0; margin-bottom: 2px;">{{ affiliate.IDType }}</p>
                            <p style="text-align: center; margin: 0;" class="borderReport">{{ affiliate.ID }}</p>
                        </v-col>
                        <v-col cols="2" style="margin: 0; padding-top: 0; padding-bottom: 0;">
                            <p style="text-align: center; font-weight: bold; margin: 0; margin-bottom: 2px;">Salario</p>
                            <p style="text-align: center; margin: 0;" v-if="!affiliate.minimumSalary" class="borderReport">{{ affiliate.salary }}</p>
                            <p style="text-align: center; margin: 0;" v-else class="borderReport">{{ country.minimumSalary }}</p>
                        </v-col>
                    </v-row>
                    <v-row style="padding: 0; margin: 0; margin-top: 6px;">
                        <v-col cols="6" style="margin: 0; padding-top: 0; padding-bottom: 0;">
                            <p style="text-align: center; font-weight: bold; margin: 0; margin-bottom: 2px;">Convenio Comercial</p>
                            <p style="text-align: center; margin: 0;" v-if="affiliate.commercialAgreement" class="borderReport">{{ affiliate.commercialAgreement }}</p>
                            <p style="text-align: center; margin: 0;" v-else class="borderReport">No Aplica</p>
                        </v-col>
                        <v-col cols="6" style="margin: 0; padding-top: 0; padding-bottom: 0;">
                            <p style="text-align: center; font-weight: bold; margin: 0; margin-bottom: 2px;">Empresa a la que se debe afiliar</p>
                            <p style="text-align: center; margin: 0;" class="borderReport">{{ contributor.name }}</p>
                        </v-col>
                    </v-row>
                    <v-row style="padding: 0; margin: 0; margin-top: 6px;">
                        <v-col cols="4" style="margin: 0; padding-top: 0; padding-bottom: 0;">
                            <p style="text-align: center; font-weight: bold; margin: 0; margin-bottom: 2px;">EPS actual o a trasladar</p>
                            <p style="text-align: center; margin: 0;" v-if="EPS" class="borderReport">{{ EPS.name }}</p>
                            <p style="text-align: center; margin: 0;" v-else class="borderReport">No Aplica</p>
                        </v-col>
                        <v-col cols="4" style="margin: 0; padding-top: 0; padding-bottom: 0;">
                            <p style="text-align: center; font-weight: bold; margin: 0; margin-bottom: 2px;">IPS asignada</p>
                            <p style="text-align: center; margin: 0;" v-if="affiliate.IPS" class="borderReport">{{ affiliate.IPS }}</p>
                            <p style="text-align: center; margin: 0;" v-else class="borderReport">No Aplica</p>
                        </v-col>
                        <v-col cols="4" style="margin: 0; padding-top: 0; padding-bottom: 0;">
                            <p style="text-align: center; font-weight: bold; margin: 0; margin-bottom: 2px;">Cargo u ocupación</p>
                            <p style="text-align: center; margin: 0;" v-if="affiliate.position" class="borderReport">{{ affiliate.position }}</p>
                            <p style="text-align: center; margin: 0;" v-else class="borderReport">No Aplica</p>
                        </v-col>
                    </v-row>
                    <v-row style="padding: 0; margin: 0; margin-top: 6px;">
                        <v-col cols="4" style="margin: 0; padding-top: 0; padding-bottom: 0;">
                            <p style="text-align: center; font-weight: bold; margin: 0; margin-bottom: 2px;">Fondo de pensión</p>
                            <p style="text-align: center; margin: 0;" v-if="AFP" class="borderReport">{{ AFP.name }}</p>
                            <p style="text-align: center; margin: 0;" v-else class="borderReport">No Aplica</p>
                        </v-col>
                        <v-col cols="4" style="margin: 0; padding-top: 0; padding-bottom: 0;">
                            <p style="text-align: center; font-weight: bold; margin: 0; margin-bottom: 2px;">Caja de compensación</p>
                            <p style="text-align: center; margin: 0;" v-if="CCF" class="borderReport">{{ CCF.name }}</p>
                            <p style="text-align: center; margin: 0;" v-else class="borderReport">No Aplica</p>
                        </v-col>
                        <v-col cols="3" style="margin: 0; padding-top: 0; padding-bottom: 0;">
                            <p style="text-align: center; font-weight: bold; margin: 0; margin-bottom: 2px;">ARL</p>
                            <p style="text-align: center; margin: 0;" v-if="ARL" class="borderReport">{{ ARL.name }}</p>
                            <p style="text-align: center; margin: 0;" v-else class="borderReport">No Aplica</p>
                        </v-col>
                        <v-col cols="1" style="margin: 0; padding-top: 0; padding-bottom: 0;">
                            <p style="text-align: center; font-weight: bold; margin: 0; margin-bottom: 2px;">Tasa</p>
                            <p style="text-align: center; margin: 0;" v-if="affiliate.ARLRate" class="borderReport">{{ affiliate.ARLRate }}</p>
                            <p style="text-align: center; margin: 0;" v-else class="borderReport">--</p>
                        </v-col>
                    </v-row>
                    <v-row style="padding: 0; margin: 0; margin-top: 6px;">
                        <v-col cols="4" style="margin: 0; padding-top: 0; padding-bottom: 0;">
                            <p style="text-align: center; font-weight: bold; margin: 0; margin-bottom: 2px;">Ciudad de residencia</p>
                            <p style="text-align: center; margin: 0;" class="borderReport">{{ affiliate.cityName }}</p>
                        </v-col>
                        <v-col cols="8" style="margin: 0; padding-top: 0; padding-bottom: 0;">
                            <p style="text-align: center; font-weight: bold; margin: 0; margin-bottom: 2px;">Dirección</p>
                            <p style="text-align: center; margin: 0;" class="borderReport">{{ affiliate.address }}</p>
                        </v-col>
                    </v-row>
                    <v-row style="padding: 0; margin: 0; margin-top: 6px;">
                        <v-col cols="4" style="margin: 0; padding-top: 0; padding-bottom: 0;">
                            <p style="text-align: center; font-weight: bold; margin: 0; margin-bottom: 2px;">Teléfono</p>
                            <p style="text-align: center; margin: 0;" class="borderReport">{{ affiliate.mobile }}</p>
                        </v-col>
                        <v-col cols="8" style="margin: 0; padding-top: 0; padding-bottom: 0;">
                            <p style="text-align: center; font-weight: bold; margin: 0; margin-bottom: 2px;">Beneficiarios</p>
                            <p style="text-align: center; margin: 0;" v-if="affiliate.beneficiaries" class="borderReport">{{ affiliate.beneficiaries }}</p>
                            <p style="text-align: center; margin: 0;" v-else class="borderReport">No Aplica</p>
                        </v-col>
                    </v-row>
                    <v-row style="padding: 0; margin: 0; margin-top: 6px;">
                        <v-col cols="12" style="margin: 0; padding-top: 0; padding-bottom: 0;">
                            <p style="text-align: center; font-weight: bold; margin: 0; margin-bottom: 2px;">Anotaciones</p>
                            <p v-if="affiliate.commentary" class="borderReport py-1 px-3" style="height: 130px;">{{ affiliate.commentary }}</p>
                            <p v-else class="borderReport py-1 px-3" style="height: 130px;">No Aplica</p>
                        </v-col>
                    </v-row>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { jsPDF } from "jspdf";
import QRCode from "qrcode";
import { prettyDate, prettyCurrency, prettyID, titleCase } from "@/modules/tools";
import Swal from "sweetalert2";
import Storage from "@/modules/storage";

const store = new Storage();

export default {
    name: 'HojaVida',
    data() {
        return {
            country: '',
            company: '',
            office: '',
            city: '',
            affiliate: '',
            branch: '',
            contributor: '',
            EPS: '',
            AFP: '',
            CCF: '',
            ARL: '',
            logo: '',
            waitLogo: true
        }
    },
    props: ["runNow"],
    watch: {
        runNow: function(value) {
            if (value) {
                this.run();
            }
        }
    },
    methods: {
        run() {
            let oneWarning = false;
            const affiliateId = this.runNow;
            const int = setInterval(()=>{
                if (this.waitLogo) {
                    if (!oneWarning) {
                        Swal.fire({
                            icon: "warning",
                            title: "Obteniendo Logotipo",
                            showConfirmButton: false,
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            allowEnterKey: false,
                            stopKeydownPropagation: true
                        });
                        oneWarning = true;
                    }
                } else {
                    this.print(affiliateId);
                    clearInterval(int);
                }
            }, 100);
        },
        async print(affiliateId) {
            Swal.fire({
                icon: "warning",
                title: "Generando Reporte",
                showConfirmButton: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                stopKeydownPropagation: true
            });

            this.country = await store.getData("country");
            this.affiliate = await store.getData("affiliate", affiliateId);
            this.branch = await store.getData("branch", this.affiliate.branch);
            this.contributor = await store.getData("contributor", this.branch.contributor);

            this.country = this.country[0];
            this.country.minimumSalary = await prettyCurrency(this.country.minimumSalary);
            this.country.minimumSalary = this.country.minimumSalary.slice(0, -3);

            this.affiliate.affiliationDate = await prettyDate(this.affiliate.affiliationDate);
            this.affiliate.ID = await prettyID(this.affiliate.ID);
            this.affiliate.salary = await prettyCurrency(this.affiliate.salary);
            this.affiliate.salary = this.affiliate.salary.slice(0, -3);
            this.affiliate.cityName = await store.getData("city", this.affiliate.city);
            this.affiliate.cityName = this.affiliate.cityName.name;
            this.affiliate.cityName = titleCase(this.affiliate.cityName);

            if (this.affiliate.EPS) {
                this.EPS = await store.getData("EPS", this.affiliate.EPS);
            } else {
                this.EPS = null;
            }
            if (this.affiliate.AFP) {
                this.AFP = await store.getData("AFP", this.affiliate.AFP);
            } else {
                this.AFP = null;
            }
            if (this.affiliate.CCF) {
                this.CCF = await store.getData("CCF", this.affiliate.CCF);
            } else {
                this.CCF = null;
            }
            if (this.contributor.ARL) {
                this.ARL = await store.getData("ARL", this.contributor.ARL);
            } else {
                this.ARL = null;
            }

            QRCode.toCanvas(
                this.$refs.canvasAf,
                this.affiliate._id,
                { errorCorrectionLevel: "H", scale: 2.6, margin: 0 },
                function(error) {
                    if (error) console.error(error);
                }
            );

            const name = this.affiliate.name;

            const doc = new jsPDF("p", "pt", "letter");
            doc.html(this.$refs.affiliateReport, {
                html2canvas: {
                scale: 0.61,
                useCORS: true
                },
                callback: function(doc) {
                doc.save(`Hoja de vida ${name}.pdf`);
                Swal.fire({
                    icon: "success",
                    title: "Hoja de Vida Generada",
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: toast => {
                    toast.addEventListener("mouseenter", Swal.stopTimer);
                    toast.addEventListener("mouseleave", Swal.resumeTimer);
                    }
                });
                }
            });
        }
    },
    async mounted() {
        this.company = await store.getData("company");
        this.office = await store.getData("office", this.$route.params._id);
        this.city = await store.getData("city", this.office.city);

        this.company = this.company[0];
        if (this.company.bannerRoute) {
            this.companyBanner = this.company.bannerRoute;
        }

        function readFileAsync(src) {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.crossOrigin = 'Anonymous';
                img.onload = function() {
                    var canvas = document.createElement('CANVAS');
                    var ctx = canvas.getContext('2d');
                    var dataURL;
                    canvas.height = this.naturalHeight;
                    canvas.width = this.naturalWidth;
                    ctx.drawImage(this, 0, 0);
                    dataURL = canvas.toDataURL();
                    resolve(dataURL);
                };
                img.onerror = function(e) {
                    reject(e);
                }
                img.src = src;
            });
        }
        try {
            this.logo = await readFileAsync(this.company.bannerRoute);
            this.waitLogo = false;
        } catch (error) {
            this.waitLogo = false;
            console.log('algo mal');
        }

        this.city.name = titleCase(this.city.name);
    },
}
</script>

<style lang="css" scoped>
.mainContainer {
    font-family: Arial, Helvetica, sans-serif;
  margin: auto;
  width: 1005px;
  height: 1298px; /* Media hoja */
  border: black 1px solid;
  background-color: white;
  color: black;
}
.reportContainer {
    margin: 15px 10px;
    height: calc(50% - 30px);
    width: calc(100% - 20px);
    border: 1px solid gray;
    border-radius: 8px;
}
.borderReport {
    border: 1px solid gray;
    border-radius: 8px;
    padding: 4px 8px;
}
.QRContainerAf {
    height: 100px;
    width: 100px;
    margin-right: 12px;
}
.QRContainerAfil {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}
</style>